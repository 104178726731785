// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-apparente-js": () => import("./../../../src/pages/case-study/apparente.js" /* webpackChunkName: "component---src-pages-case-study-apparente-js" */),
  "component---src-pages-case-study-case-study-page-jsx": () => import("./../../../src/pages/case-study/CaseStudyPage.jsx" /* webpackChunkName: "component---src-pages-case-study-case-study-page-jsx" */),
  "component---src-pages-case-study-idm-js": () => import("./../../../src/pages/case-study/idm.js" /* webpackChunkName: "component---src-pages-case-study-idm-js" */),
  "component---src-pages-case-study-molendini-js": () => import("./../../../src/pages/case-study/molendini.js" /* webpackChunkName: "component---src-pages-case-study-molendini-js" */),
  "component---src-pages-case-study-neko-js": () => import("./../../../src/pages/case-study/neko.js" /* webpackChunkName: "component---src-pages-case-study-neko-js" */),
  "component---src-pages-case-study-nerarium-js": () => import("./../../../src/pages/case-study/nerarium.js" /* webpackChunkName: "component---src-pages-case-study-nerarium-js" */),
  "component---src-pages-case-study-osservatorio-js": () => import("./../../../src/pages/case-study/osservatorio.js" /* webpackChunkName: "component---src-pages-case-study-osservatorio-js" */),
  "component---src-pages-case-study-quinaryo-js": () => import("./../../../src/pages/case-study/quinaryo.js" /* webpackChunkName: "component---src-pages-case-study-quinaryo-js" */),
  "component---src-pages-case-study-sushi-ii-js": () => import("./../../../src/pages/case-study/sushi-ii.js" /* webpackChunkName: "component---src-pages-case-study-sushi-ii-js" */),
  "component---src-pages-case-study-tedxluiss-js": () => import("./../../../src/pages/case-study/tedxluiss.js" /* webpackChunkName: "component---src-pages-case-study-tedxluiss-js" */),
  "component---src-pages-case-study-toefrom-js": () => import("./../../../src/pages/case-study/toefrom.js" /* webpackChunkName: "component---src-pages-case-study-toefrom-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informativa-privacy-js": () => import("./../../../src/pages/informativa-privacy.js" /* webpackChunkName: "component---src-pages-informativa-privacy-js" */),
  "component---src-pages-servizi-2-js": () => import("./../../../src/pages/servizi2.js" /* webpackChunkName: "component---src-pages-servizi-2-js" */),
  "component---src-pages-servizi-js": () => import("./../../../src/pages/servizi.js" /* webpackChunkName: "component---src-pages-servizi-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

