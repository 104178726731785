module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alfatauri Studio","short_name":"Alfatauri Studio","description":"Alfatauri Studio è una web agency con sede a Roma, che si occupa di comunicazione, design e sviluppo app e software.","lang":"it","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/logo-icon.png","localize":[{"start_url":"/en/","lang":"en","name":"Alfatauri Studio","short_name":"Alfatauri Studio","description":"Alfatauri Studio is a web agency placed in Rome that works on communication, design software development."}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"958b223d32ea5ab8c7c24bbceaf1cb85"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132085365-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.alfatauristudio.it"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
